import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col } from "react-bootstrap";
import header from "../../Assets/Resources/Blogs/blogs.webp"

const getBlogs = async () => {

  return await axios({
      method: "GET",
      url:"https://api.dev.dataequity.io/website/blogs",
    }).then((response)=> {
      return response;
    })
}

function Blogs() {

  const [entries, setEntries] = useState();

  useEffect(() => {
    async function fetchBlogs() {
      const blogs = await getBlogs();
      if(blogs) {
        const { data } = blogs
        setEntries(data)
      }
    }
    fetchBlogs();
  }, []);

  const navigate = useNavigate(); 
  const titleClick = (slug, entry) => {
    const path = `/blogs/${slug}`;
    navigate(path, {state : entry});
  }

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="productHeader">
            <img src={header} className="img-fluid productHeader" alt="blogs" />
          </Col>
        </Row>
        <Row>
        { entries && entries.map((entry, index) => {
            return (
              <Col md={12} className="blog-entry" key={index++}>
                <p className="blog">
                Published on: {entry.fields.publishedDate}
                </p>
                <p className="blog-header" onClick={() => titleClick(entry.fields.slug, entry)}>
                  {entry.fields.title}
                </p>
                <p className="blog" onClick={() => titleClick(entry.fields.slug, entry)}>
                {entry.fields.content?.content[0]?.content[0]?.value} <span className="blog-more">More...</span>
                </p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
}


export { Blogs, getBlogs};
