import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import header from "../../Assets/Products/Toolbox/header.webp";
import img1 from "../../Assets/Products/Toolbox/1.jpg";
import img2 from "../../Assets/Products/Toolbox/2.jpg";
import img3 from "../../Assets/Products/Toolbox/3.jpg";
import img4 from "../../Assets/Products/Toolbox/4.jpg";
import Tilt from "react-parallax-tilt";

function APIToolbox() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="productHeader">
            <img src={header} className="img-fluid productHeader" alt="APIToolbox" />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Realize the Equity of Data using DE Curator</span> 
            </h1>
            <p className="home-about-body">
            DE Curator is a powerful platform designed to streamline Data Discovery, Valuation, API development 
            and management. With its AI powered IAVF (Information Asset Valuation Framework) engine, a user-friendly interface and vast array of 
            tools and integrations, it offers everything you need to valuate, create, test and monitor APIs. 
            </p>
            <p className="home-about-body">
              In today's digital landscape, APIs are 
              essential for businesses that want to create innovative and efficient solutions 
              for their customers. APIs allow businesses to connect different applications and 
              systems, streamlining processes, and enhancing customer experience. However, with so 
              many APIs available and new ones being created every day, managing and integrating APIs 
              can be a daunting task.
            </p>
            <p className="home-about-body">
              That's where our product, DE Curator, comes in. Our product is designed to help 
              businesses harness the power of APIs by providing a comprehensive set of tools that 
              make it easy to discover, manage, and integrate APIs into their applications. 
              With DE Curator, businesses can access a vast library of APIs from different providers, 
              enabling them to find the best APIs for their needs quickly.
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img1} className="img-fluid engineering" alt="APIToolbox" />
            </Tilt>
          </Col>
        </Row>
        <Row className="row flex-column-reverse flex-md-row">
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img2} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Asset Valuation</span> 
            </h1>
            <p className="home-about-body">
            DE Curator offers a powerful data asset valuation tool that can help
            you asses your data equity and make data-driven decisions with ease. Take
            advantage of its automated solutions and real-time analytics to maximize business
            potential and minimize human interference 
            </p>
            <p className="home-about-body">
            With our product, businesses can easily organize and catalog their API assets, 
            making it easy for developers and partners to discover and use them. Our product 
            also offers version control features, enabling businesses to track changes and 
            updates to their API assets over time.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">API Documentation</span> 
            </h1>
            <p className="home-about-body">
            DE Curator is a perfect way to leverage the power of APIs for you business.
            Their integration as and API Documentation allow you to quickly and easily
            implement APIs into your workflow, giving you the power to extend your capabilities
            </p>
            <p className="home-about-body">
            Our product enables businesses to generate user-friendly and interactive 
            documentation that makes it easy for developers to understand and integrate APIs 
            into their applications. Our API documentation solution includes customizable templates, 
            version control features, and advanced editing tools, enabling businesses to create and 
            maintain documentation that matches their brand guidelines and standards. Additionally, 
            our product offers analytics and reporting features that allow businesses to track 
            documentation usage and performance, and make data-driven decisions to optimize their 
            API strategy.
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img3} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
        </Row>
        <Row className="row flex-column-reverse flex-md-row">
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img4} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Pricing and Delivery policies</span> 
            </h1>
            <p className="home-about-body">
            DE Curator provides powerful solutions to access data in a secure and cost-effective manner. 
            Their pricing and delivery policies are designed to ensure reliable and timely delivery of services, 
            without compromising on quality. Get the best out of your data and harness the power of DE Curator
            </p>
            <p className="home-about-body">
            With our product, businesses can easily set and adjust pricing for their products and services, 
            including customized pricing models based on usage, subscriptions, and other factors. 
            Our platform also offers robust delivery policy management, enabling businesses to 
            configure and optimize shipping and fulfillment options. Additionally, our product 
            includes analytics and reporting tools that enable businesses to track pricing and 
            delivery performance, and make data-driven decisions to improve customer experience 
            and business growth.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default APIToolbox;
