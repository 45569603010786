import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import Contactus from "./Contactus";
import de from "../../Assets/About/about.webp";
import locations from "../../Assets/About/Locations.webp";
import sandeep from "../../Assets/About/sandeep.webp";
import harish from "../../Assets/About/harish.webp";
import Mission from "./Mission";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bolder" }}>
              About us
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "10px" }}
            className="about-img"
          >
            <img src={de} alt="about" className="img-fluid" style={{ height: "75%"}}/>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Mission />
        </Row>

        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "2.1em", fontWeight: "bolder" }}>
              Founders
            </h1>
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={6}
            style={{
              justifyContent: "center",
              paddingTop: "10px",
              borderStyle: "inset",
              borderRadius: "15px",
            }}
          >
            <img src={sandeep} alt="Sandeep Sundaram" className="about-founders" />
            <blockquote className="founders mb-0">
              <p><b>Sandeep Sundaram</b></p>
              <p style={{ textAlign: "left", padding: "10px" }}>
              Sandeep Sundaram is an experienced business leader focussed on digital technologies with a strong domain and technology experience spanning consulting, architecture, systems integration, pre-sales and execution spanning 20 years.
              Prior to founding Dataequity, he had founded Perse and also operated as the Chief Technology Officer for DigitalAPICraft and established the API-led approach that enabled retail, banking, insurance, telecom, and media companies to offer delightful customer experience, transform business processes and boost operational efficiency.
              </p>
            </blockquote>
          </Col>
          <Col
            md={6}
            style={{
              justifyContent: "center",
              paddingTop: "10px",
              borderStyle: "inset",
              borderRadius: "15px",
            }}
          >
            <img src={harish} alt="Harish Gopal" className="about-founders" />
            <blockquote className="founders mb-0">
            <p><b>Harish Gopal</b></p>
            <p style={{ textAlign: "left", padding: "10px" }}>
              Harish Gopal brings onboard 22 years of experience in an eclectic mix of executive leadership roles over the years. He has been actively
              involved in product strategy and consulting for complex IT programmes in banking, utilties and other digital technologies. He was the Chief Executive Officer for Enzen Spain and head of Transaction Advisory Services 
              for Enzen Global  Limited, UK - Winning, leading and delivering transactions worth over 30 Bn USD globally for blue chip investment banks and 
              infrastructure funds including Macquarie Infrastructure and Real Assets UK, Deutsche Bank Spain, UBS UK, etc.
            </p>
            </blockquote>
          </Col>
        </Row>
        
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={12}
            style={{
              justifyContent: "center",
              paddingTop: "90px",
              paddingBottom: "20px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bolder" }}>
              Our Locations
            </h1>
            <img src={locations} alt="locations" className="img-fluid" />
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={8}
            style={{
              paddingBottom: "50px",
            }}
          >
            <Contactus/>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
