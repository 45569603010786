import React, { useState } from 'react'
import axios from 'axios'
import validator from 'validator'

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState('Submit')
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    let conFom = {
      name,
      email,
      message
    }
    axios({
        method: "POST",
        url:"https://api.dev.dataequity.io/website/sendmail",
        data: conFom
      }).then((response)=>{
        if (response.data.status === 'success') {
            setSuccess("Submitted successfully");
            resetForm()
            setFormStatus('Submit')
        } else {
            setErrors({"submit": "Error!"});
        }
      })
  }

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  }

  const onNameChange = (event) => {
    const newName = event.target.value
    setName(newName)

    if (!newName.match(/^[a-zA-Z]+$/)) {
        setErrors({"name": "Please do not enter any number"});
    } else {
        setErrors({"name": ""});
    }
  }

  const onEmailChange = (event) => {
    const email = event.target.value
    setEmail(email)

    if (!validator.isEmail(email)) {
        setErrors({"email": "Please enter a valid email"});
    } else {
        setErrors({"email": ""});
    }
  }

  const onMessageChange = (event) => {
    setMessage(event.target.value)
  }

  return (
    <div className="container mt-5 contact-sales-section">
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontWeight: "bolder" }}>
            Contact us
        </h1>
        <span style={{ color: "green" }}>{success}</span>
        <span style={{ color: "red" }}>{errors["submit"]}</span>
        <form onSubmit={handleSubmit} method="POST">
          <div className="form-group">
            <label className="d-flex my-1 me-2" htmlFor="name">Name</label>
            <span style={{ color: "red" }}>{errors["name"]}</span>
            <input name="name" id="name" type="text" className="form-control" value={name} 
                placeholder="Please enter your full name" onChange={onNameChange} required/>
            
          </div>

          <div className="form-group">
            <label className="d-flex my-1 me-2" htmlFor="email">Email address</label>
            <input name="email" id="email" type="email" className="form-control" value={email} 
                placeholder="Please enter your email" onChange={(e) => onEmailChange(e)} required/>
            <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.<a className="tc" href="/terms-of-use">Terms of use</a>
            </small>
            <span style={{ color: "red" }}>{errors["email"]}</span>
          </div>

          <div className="form-group">
            <label className="d-flex my-1 me-2" htmlFor="message1">Message</label>
            <textarea name="message" id="message1" className="form-control" rows="5" value={message} 
                placeholder="Please enter your message" onChange={e => onMessageChange(e)} required/>
          </div>

          <br/>
          <button type="submit" className="btn btn-primary">{formStatus}</button>
        </form>
    </div>
  )
}

export default ContactForm