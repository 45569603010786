import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Curate & Expose APIs",
          "AI Driven",
          "Data Discovery & Valuation",
          "API Management",
          "API Marketplace",
          "API Monetization",
          "Knowledge Bots",
          "Cloud Native"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 10,
      }}
    />
  );
}

export default Type;
