import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import header from "../../Assets/Products/Chatbot/header.webp";
import img1 from "../../Assets/Products/Chatbot/1.jpg";
import img2 from "../../Assets/Products/Chatbot/2.jpg";
import img3 from "../../Assets/Products/Chatbot/3.jpg";
import img4 from "../../Assets/Products/Chatbot/4.jpg";
import img5 from "../../Assets/Products/Chatbot/5.jpg";
import Tilt from "react-parallax-tilt";

function Chatbot() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="productHeader">
            <img src={header} className="img-fluid productHeader" alt="sectionImage" />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Revolutionize your customer service with DE InfoBot</span> 
            </h1>
            <p className="home-about-body">
            Introducing a chatbot to revolutionize your customer service with 24/7 availability. 
            This solution provides a cost-effective and convenient way to engage with customers
            and meet their queries regardless of time and location
              <br />
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img1} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
        </Row>
        <Row className="row flex-column-reverse flex-md-row">
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img2} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Seamless Integration</span> 
            </h1>
            <p className="home-about-body">
            Integrating a chatbot into your customer service processes can vastly improve the 
            customer experience. Seamless integration with your existing site and other CRM systems 
            will make the chatbot a powerful business tool.
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Customizable Responses</span> 
            </h1>
            <p className="home-about-body">
            The DE infobots can be trained to show your business data to the customers. With this 
            level of controlled information input, you will be able to add checks and controls to the 
            information being shared by the chatbot. Every information provided can be tested in the 
            playground before releasing it for public consumption.
              <br />
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img4} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
        </Row>
        <Row className="row flex-column-reverse flex-md-row">
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img5} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Reports and Analytics</span> 
            </h1>
            <p className="home-about-body">
            All information processed by the chatbot will be recorded for audit and analysis. This 
            information can be replayed later when the customer returns as well.
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Friendly interface</span> 
            </h1>
            <p className="home-about-body">
              We provide a friendly interface for model selection and training. A super-user can use
              the admin screens for repeated training and fine tuning of data.
              <br />
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img3} className="img-fluid engineering" alt="sectionImage" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Chatbot;
