import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from "react-bootstrap/Container";
import logoName from "../Assets/Home/Data_Equity.png";
import { Link } from "react-router-dom";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [showProdDropdown, setShowProdDropdown] = useState(false);
  const [showResDropdown, setShowResDropdown] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
      setShowProdDropdown(false);
      setShowResDropdown(false);
    } else {
      updateNavbar(false);
    }
  }

  function handlekey(e) {
    setShowProdDropdown(false);
    setShowResDropdown(false);
  }

  useEffect(() => { 
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("keydown", handlekey);
    window.addEventListener("click", handlekey);
    return () => {
      window.removeEventListener("keydown", handlekey);
    };
  }, []);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logoName} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="/home">

            <Nav.Item onClick={() => setShowProdDropdown(false)}
                      onMouseOver={() => {setShowProdDropdown(false); setShowResDropdown(false)}}>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item 
              onMouseOver={() => { setShowProdDropdown(true); setShowResDropdown(false)}}
              onFocus={() => setShowProdDropdown(true)}
              onBlur={() => setShowProdDropdown(false)}
              onClick={() => setShowProdDropdown(!showProdDropdown)}>
              <NavDropdown title="Products" show={showProdDropdown}>
                <NavDropdown.Item href="/apicurator">DE Curator</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/apimarketplace">DE Marketplace</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/infobot">DE InfoBot</NavDropdown.Item>
              </NavDropdown>                
            </Nav.Item>

            <Nav.Item onClick={() => setShowProdDropdown(false)}
                      onMouseOver={() => { setShowProdDropdown(false); setShowResDropdown(false)}}>
              <Nav.Link as={Link} to="/about" onClick={() => updateExpanded(false)}>
                About
              </Nav.Link>
            </Nav.Item>

            <Nav.Item 
              onMouseOver={() => { setShowResDropdown(true); setShowProdDropdown(false) }}
              onFocus={() => setShowResDropdown(true)}
              onBlur={() => setShowResDropdown(false)}
              onClick={() => setShowResDropdown(!showResDropdown)}>
              <NavDropdown title="Resources" show={showResDropdown}>
                <NavDropdown.Item href="/whitepapers">Whitepapers</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/blogs">Blogs</NavDropdown.Item>
              </NavDropdown>                
            </Nav.Item>

            <Nav.Item onClick={() => setShowProdDropdown(false)}
                      onMouseOver={() => { setShowProdDropdown(false); setShowResDropdown(false)}}>
              <Nav.Link as={Link} to="/contact-sales" onClick={() => updateExpanded(false)}
              style={{ border: '1px solid #fff'}}>
                Contact Sales
              </Nav.Link>
            </Nav.Item>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
