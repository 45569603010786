import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Terms() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Terms of Use</span> 
            </h1>
            <p className="home-about-body">
            Last Updated: Mar 30, 2023
            </p>
            <p className="home-about-body">
              Howdy! We have summarized the Terms of Use to be easily understood.
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>Your email address or other personal details will not be used for any other purpose, and will not be shared with any third parties.
              </li>
              <li>You are responsible for maintaining the confidentiality of your email address and any other information you provide to us.
              </li>
              <li>We reserve the right to terminate your access to the products if we believe you have violated these terms of use or any applicable laws.
              </li>
              <li>These terms of use may be updated at any time, and your continued use of the products constitutes acceptance of any changes.
              </li>
              <li>By using our products, you agree to these terms of use and understand the conditions under which your email address will be collected and used. If you do not agree to these terms, please do not use the products. Let us know if you have any questions or concerns.
              </li>
            </ul>
            <p className="home-about-body">
            <b>Refund Policy:</b> if you have purchased a paid subscription and you're unhappy with your 
            purchase for any reason, email us at support@dataequity.io within 14 days of your purchase 
            and we'll refund you, no questions asked. Please note that you won't be able to use our 
            paid products after you cancel your subscription.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Terms;
