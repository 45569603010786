import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import pdf from "../../Assets/Resources/Whitepapers/pdf.png";
import Tilt from "react-parallax-tilt";

const whitepaperInfo = {
  car: {fileName:"Car-Retail_Whitepaper.pdf", text:"The Advantages of APIs in Used Car Retail"},
  construction: {fileName:"Construction-whitepaper.pdf", text:"Utilizing APIs in Construction Industry"},
  gaming: {fileName:"Gaming-whitepaper.pdf", text:"Enhancing Gaming Experience with APIs"},
  chat: {fileName:"Chatbots-whitepaper.pdf", text:"Using Customisable and Extensible Chatbots for Smarter Customer Services"}
}

function Whitepapers() {

  let navigate = useNavigate(); 
  const buttonClick = (name) => {
    let path = `/whitepapers/userInfo/${name}`; 
    navigate(path);
  }

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={4} className="sectionImage">
            <div className="whitepaper">
              <Tilt>
                <img src={pdf} className="img-fluid whitepaper-image" alt="pdf" />
              </Tilt>
              <div className="whitepaper-description">
                {whitepaperInfo.car.text}
              </div>
              <button onClick={() => buttonClick('car')} className="btn btn-primary">Download</button>
            </div>
          </Col>
          <Col md={4} className="sectionImage">
            <div className="whitepaper">
              <Tilt>
                <img src={pdf} className="img-fluid whitepaper-image" alt="pdf" />
              </Tilt>
              <div className="whitepaper-description">
                {whitepaperInfo.construction.text}
              </div>
              <button onClick={() => buttonClick('construction')} className="btn btn-primary">Download</button>
            </div>
          </Col>
          <Col md={4} className="sectionImage">
            <div className="whitepaper">
              <Tilt>
                <img src={pdf} className="img-fluid whitepaper-image" alt="pdf" />
              </Tilt>
              <div className="whitepaper-description">
                {whitepaperInfo.gaming.text}
              </div>
              <button onClick={() => buttonClick('gaming')} className="btn btn-primary">Download</button>
            </div>
          </Col>
        </Row>

        <Row>
        <Col md={4} className="sectionImage">
            <div className="whitepaper">
              <Tilt>
                <img src={pdf} className="img-fluid whitepaper-image" alt="pdf" />
              </Tilt>
              <div className="whitepaper-description">
                {whitepaperInfo.chat.text}
              </div>
              <button onClick={() => buttonClick('chat')} className="btn btn-primary">Download</button>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export {Whitepapers, whitepaperInfo};
