import Home from "./components/Home/Home";
import About from "./components/About/About";
import {
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import APIToolbox from "./components/Products/ApiToolbox";
import Marketplace from "./components/Products/Marketplace";
import Chatbot from "./components/Products/Chatbot";
import Terms from "./components/Terms-Privacy/Terms";
import Privacy from "./components/Terms-Privacy/Privacy";
import ContactForm from "./components/About/Contactus";
import { Whitepapers } from "./components/Resources/Whitepapers";
import WhitepapersUserInfo from "./components/Resources/Whitepapers-userInfo";
import { Blogs } from "./components/Resources/Blogs";
import BlogPage from "./components/Resources/Blog-page";
import ContactSales from "./components/ContactSales/ContactSales";

function WebsiteRoutes() {
  return (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />  
          <Route path="/apicurator" element={<APIToolbox />} />
          <Route path="/apimarketplace" element={<Marketplace />} />
          <Route path="/infobot" element={<Chatbot />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactForm />} />
          <Route path="/contact-sales" element={<ContactSales />} />
          <Route path="/whitepapers" element={<Whitepapers />} />
          <Route path="/whitepapers/userInfo/:section" element={<WhitepapersUserInfo />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:slug" element={<BlogPage />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
  )
}

export default WebsiteRoutes;
