import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Privacy() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Privacy Policy</span> 
            </h1>
            <p className="home-about-body">
            Last Updated: Mar 30, 2023
            </p>
            <p className="home-about-body">
            We take your privacy seriously. We only collect and use your email address for 
            the purpose of authenticating your access to our Products. Your email address will 
            not be used for any other purpose, and will not be shared with any third parties.
            </p>
            <p className="home-about-body">
            We make every effort to protect your personal information and keep it secure. 
            However, please be aware that no internet transmission is 100% secure, 
            and we cannot guarantee the security of your information. By using our Products, 
            you accept the inherent risks of providing information online and will not hold 
            us responsible for any breach of security.
            </p>
            <p className="home-about-body">
            If you have any questions or concerns about our privacy policy, 
            please contact us at support@dataequity.io
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Privacy;
