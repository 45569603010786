import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiOutlineTwitter,
  AiOutlineLinkedin
} from "react-icons/ai";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copyright">
          <h3>Copyright © {year} Data Equity. All rights reserved</h3>
        </Col>
        <Col md="4" className="footer-body">
        <ul>
            <li className="tc">
              <a
                className="tc"
                href="/terms-of-use"
                style={{ color: "white"}}
              > Terms of Use
              </a>
            </li>
            <li className="tc">
              <a
                className="tc"
                href="/privacy-policy"
                style={{ color: "white" }}
              >Privacy Policy
              </a>
            </li>
          </ul>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/company/thedataequity"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiOutlineLinkedin />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/thedataequity"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
