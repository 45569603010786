import React from "react";
import { Col } from "react-bootstrap";

function Mission() {
  return (
    <>
    <Col
      md={7}
      style={{
        justifyContent: "center",
        paddingTop: "30px",
        paddingBottom: "50px",
      }}
    >
      <h1 style={{ fontSize: "1.8em", fontWeight: "bold" ,paddingBottom: "10px" }}>
        Our Mission
      </h1>

      <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "center" }}>
          Unlock the hidden equity of your data.
        </p>
      </blockquote>
    </Col>

    <Col
      md={7}
      style={{
        justifyContent: "center",
        paddingTop: "30px",
        paddingBottom: "50px",
      }}
    >
      <h1 style={{ fontSize: "1.8em", fontWeight: "bold" ,paddingBottom: "10px" }}>
        Our Vision
      </h1>

      <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "center" }}>
        We use Generative AI to an Open Data Discovery platform to connect to your enterprise data and provide data valuation, API recommendations and facilitate 
        data monetization through your APIs published on our API Marketplace.
        </p>
      </blockquote>
    </Col>

    <Col
      md={7}
      style={{
        justifyContent: "center",
        paddingTop: "30px",
        paddingBottom: "50px",
      }}
    >
      <h1 style={{ fontSize: "1.8em", fontWeight: "bold" ,paddingBottom: "10px" }}>
        Our Culture
      </h1>

      <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "center" }}>
        We place a strong emphasis on innovation and creativity. We encourage employees to think outside the box, experiment with new ideas, 
        and push the boundaries of what AI can achieve. Creativity is valued not only in research and development but also in problem-solving 
        and finding novel solutions for clients.
        </p>
      </blockquote>
    </Col>
    </>
  );
}

export default Mission;
