import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import header from "../../Assets/Products/Marketplace/header.webp";
import img1 from "../../Assets/Products/Marketplace/1.jpg";
import img2 from "../../Assets/Products/Marketplace/2.jpg";
import img3 from "../../Assets/Products/Marketplace/3.jpg";
import img4 from "../../Assets/Products/Marketplace/4.jpg";
import img5 from "../../Assets/Products/Marketplace/5.jpg";
import Tilt from "react-parallax-tilt";

function Marketplace() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="productHeader">
            <img src={header} className="img-fluid productHeader" alt="sectionImage" />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">A Marketplace for APIs</span> 
            </h1>
            <p className="home-about-body">
            Our API Marketplace will enable you to realize the value of your data exposed through APIs.
            We provide a customizable and extendable API marketplace to list, manage and monetize your APIs along
            with your the APIs provided your third-party ecosystem. You can use the self-service UI to onboard the 
            APIs and manage your users and partners.
            </p>
            <p className="home-about-body">
            In the digital world, APIs are becoming an essential component of modern businesses. 
            APIs are the building blocks that enable developers to create innovative and efficient 
            solutions for their customers. However, with so many APIs available and new ones being 
            created every day, discovering and integrating the right APIs can be a challenge.
            </p>
            <p className="home-about-body">
            That's where our product, a Marketplace for APIs, comes in. 
            Our platform offers a vast library of APIs from different providers, making it 
            easy for developers to find and integrate APIs into their applications. 
            Our marketplace provides developers with a single platform where they can browse, 
            compare, and purchase APIs from different providers, streamlining the integration 
            process and saving time and resources.
            </p>
            <p className="home-about-body">
            Our platform also offers advanced search and filtering tools that enable developers to 
            find APIs that match their specific requirements, whether it's for data analytics, 
            social media, payments, or other use cases. Additionally, our platform provides 
            businesses with access to valuable analytics and reporting tools that allow them to 
            track API usage and performance, enabling them to optimize their API strategy and make 
            data-driven decisions to drive growth.
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img1} className="img-fluid engineering" alt="Marketplace" />
            </Tilt>
          </Col>
        </Row>
        <Row className="row flex-column-reverse flex-md-row">
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img2} className="img-fluid engineering" alt="Features" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Features and Benefits</span> 
            </h1>
            <p className="home-about-body">
            API Marketplace offers a variety of features and benefits to its users. Your APIs can be easily
            discovered, understood and utilized by prospective customers. The intuitive UI will provide a great
            experience to the users in trying out the APIs and deciding on a purchase plan.
            </p>
            <p className="home-about-body">
            Here are some of the features and benefits of having an API marketplace:
            <ul>
              <li><b>Offer a wide selection of APIs:</b> An API marketplace can offers a vast selection of APIs from different providers, making it easy for businesses and developers to find the right APIs for their needs.</li>
              <li><b>Simplified integration:</b> An API marketplace provides a simplified integration process, with clear documentation and guidelines that make it easy for developers to integrate APIs into their applications.</li>
              <li><b>Flexibility:</b> An API marketplace offers businesses and developers the flexibility to choose from different pricing models, including subscription-based models, pay-as-you-go, and freemium models.</li>
              <li><b>Cost-effectiveness:</b> API marketplaces can help businesses and developers save money by providing access to the SaaS platform at lower costs than building it from scratch.</li>
              <li><b>Improved developer experience:</b> An API marketplace provides a better developer experience by providing advanced search and filtering tools, comprehensive documentation, and support resources.</li>
              <li><b>Faster time-to-market:</b> By providing access to pre-built Portal, our API marketplace can help businesses and developers accelerate time-to-market and quickly launch new products and services.</li>
            </ul>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">API Documentation</span> 
            </h1>
            <p className="home-about-body">
            Documentation is the crucial part of creating an attractive marketplace. It should be clear concise 
            and easily accessible to the users to better understand the API. We provide API Documentation tools 
            like Swagger, Redoc, Graphiql, AsyncAPI etc to visualize and try out the APIs.
            </p>
            <p className="home-about-body">
            Our API documentation product includes a user-friendly interface that allows developers to 
            create, edit, and publish documentation quickly and easily. We also provide customizable 
            templates that enable businesses to brand their API documentation and make it consistent
             with their brand guidelines.
            </p>
            <p className="home-about-body">
            Our product includes advanced search and filtering tools that make it easy for developers to 
            find the information they need quickly. We also provide code samples and examples that 
            illustrate how to use the API effectively, as well as comprehensive technical specifications 
            and usage guidelines.
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img5} className="img-fluid engineering" alt="Documentation" />
            </Tilt>
          </Col>
        </Row>
        <Row className="row flex-column-reverse flex-md-row">
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img4} className="img-fluid engineering" alt="Pricing" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Pricing plans</span> 
            </h1>
            <p className="home-about-body">
            Depending upon the business priorities and policies, an enterprise can decide to provide Free, 
            Freemium and Paid models for APIs. We make it easy to bundle APIs into API packages and track the 
            purchase as well as usage of the APIs.
            </p>
            <p className="home-about-body">
            Our pricing plan product includes a user-friendly interface that makes it easy to 
            create and modify pricing plans quickly and easily. We offer a range of pricing models, 
            including subscription-based models, pay-as-you-go models, and freemium models, 
            enabling businesses to choose the model that best fits their needs.
            </p>
            <p className="home-about-body">
            Our product also provides advanced pricing management options that enable businesses 
            to monitor pricing performance, control access, and manage usage. We provide comprehensive 
            analytics and reporting tools that help businesses to track pricing usage and performance, 
            making it easy to make data-driven decisions that drive growth and profitability.
            </p>
            <p className="home-about-body">
            Our pricing plan product is designed to be highly customizable, with the ability to 
            create custom pricing rules and models that meet the unique needs of each business. 
            We offer a range of integration options, enabling businesses to seamlessly integrate 
            our pricing plan product with their existing systems and workflows.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Workflows and Controls</span> 
            </h1>
            <p className="home-about-body">
            We provide many pre-built workflows that allow seamless on-boarding and management of users,
            APIs and API products. All entities are managed as APIs and thus we allow API based content/entity 
            onboarding and management
            </p>
            <p className="home-about-body">
            Our product includes a range of features and tools that enable businesses to create custom 
            workflows and controls that meet the unique needs of their organization. We offer user-friendly 
            interfaces that make it easy to manage the onboarding process, control access, and monitor usage.
            </p>
            <p className="home-about-body">
            Our product provides advanced security features that help businesses to protect their APIs 
            and sensitive data. We offer authentication and authorization controls, as well as advanced 
            access management tools that enable businesses to control who has access to their APIs and data.
            </p>
            <p className="home-about-body">
            Our product is highly customizable, with the ability to create custom workflows, rules, and 
            controls that meet the unique needs of each business. We also provide comprehensive analytics 
            and reporting tools that enable businesses to track developer usage and performance, making it 
            easy to make data-driven decisions that drive growth and profitability.
            </p>
          </Col>
          <Col md={4} className="sectionImage">
            <Tilt>
              <img src={img3} className="img-fluid engineering" alt="Workflows" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Marketplace;
