import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CookieConsent from "react-cookie-consent";
import WebsiteRoutes from "./Routes";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <WebsiteRoutes/>
        <Footer />
      </div>
    <CookieConsentMessage/>
    </Router>
  );
}

function CookieConsentMessage() {
  return (
    <CookieConsent
    location="bottom"
    buttonText="close"
    style={{ background: "#1b1a2e" }}
    buttonStyle={{ background: "#c771f1", fontSize: "13px", color: "#fff" }}
    expires={150}
  >
    This website uses cookies for analytics.
  </CookieConsent>
  )
}

export default App;
