import React, {Suspense} from "react";
import Cover from "./Cover";
import { BiLoaderCircle } from "react-icons/bi";
const Products = React.lazy(() => import ("./Products"));
const TrustedBy = React.lazy(() => import ("./TrustedBy"));
const GetStarted = React.lazy(() => import ("./GetStarted"));
const BuiltOn = React.lazy(() => import ("./BuiltOn"));
const HowItWorks = React.lazy(() => import ("./HowItWorks"));
const DesignedFor = React.lazy(() => import ("./DesignedFor"));
const AreYouReady = React.lazy(() => import ("./AreYouReady"));


function Home() {

  return (
    <section>
      <Cover />
      
      <Suspense fallback={<BiLoaderCircle />}>
        <BuiltOn />
        <Products />
        <HowItWorks />
        <DesignedFor />
        <AreYouReady />
        <TrustedBy />
        <GetStarted />
      </Suspense>
    </section>
  );
}

export default Home;
