import React, {useEffect, useState} from "react";
import {useLocation, useParams} from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { getBlogs } from "./Blogs";

function BlogPage() {
  const location = useLocation();
  const { slug } = useParams();

  const { fields } = location.state || {};
  const [entry, setEntry] = useState();

  useEffect(() => {
    async function fetchBlogs() {
      const blogs = await getBlogs();
      if(blogs) {
        const { data } = blogs
        const filteredData = data.find(d => d.fields.slug === slug)
        setEntry(filteredData.fields)
      }
    }

    if(fields) 
      setEntry(fields);
    else
      fetchBlogs();

        
    console.log(fields)

  }, [slug, fields]);

  return (
    <Container fluid className="blog-section" id="about">
      <Container>
        <Row>
        { entry && (
              <Col md={12} className="blog-entry">
                <p className="blog">
                Published on: {entry.publishedDate}
                </p>
                <p className="blog-header">
                  {entry.title}
                </p>
                {entry.content?.content.map(content => {

                  if(content.nodeType === 'heading-1') 
                  return (
                    <h1 className="blog-page-heading">
                      {
                        content.content.map(content1 => content1.value)
                      }
                    </h1>
                  )

                  if(content.nodeType === 'heading-2') 
                  return (
                    <h2 className="blog-page-heading">
                      {
                        content.content.map(content1 => content1.value)
                      }
                    </h2>
                  )

                  if(content.nodeType === 'heading-3') 
                  return (
                    <h3 className="blog-page-heading">
                      {
                        content.content.map(content1 => content1.value)
                      }
                    </h3>
                  )

                  if(content.nodeType === 'heading-4') 
                  return (
                    <h4 className="blog-page-heading">
                      {
                        content.content.map(content1 => content1.value)
                      }
                    </h4>
                  )

                  if(content.nodeType === 'heading-5') 
                  return (
                    <h5 className="blog-page-heading">
                      {
                        content.content.map(content1 => content1.value)
                      }
                    </h5>
                  )

                  if(content.nodeType === 'paragraph') 
                    return (
                      <p className="blog-page">
                        {
                        content.content[0]?.marks.length === 0 ?
                          content.content[0]?.value
                            :
                          content.content.map(content1 => content1.marks.length > 0 ? 
                          <b>{content1.value}</b> : content1.value)
                        }
                      </p>
                  )

                  if(content.nodeType === 'ordered-list'|| content.nodeType === 'unordered-list')
                    return (
                      content.content.map(content1 =>
                        <ul style={{textAlign: "left"}}>
                           {
                            <li>
                              {content1.content.map(content2 => 
                                content2.content.map(content3 => 
                                  content3.marks?.length === 0 ? (content3.nodeType === 'text' ? content3.value : <p>{ content3.value }</p>) : <b>{ content3.value }</b>
                              ))}
                            </li>
                            }
                        </ul>
                      ))
                  }
                )}
              </Col>
            )
          }
          <p className="blog-page">
            Book a Free <a href="https://dataequity.io/contact-sales">Demo Now!</a>
          </p>
        </Row>
      </Container>
    </Container>
  );
}
export default BlogPage;
