import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import validator from 'validator'
import { Container, Row, Col } from "react-bootstrap";
import pdf from "../../Assets/Resources/Whitepapers/pdf.png";
import Tilt from "react-parallax-tilt";
import { whitepaperInfo } from './Whitepapers'

function WhitepapersUserInfo() {
  const [formStatus, setFormStatus] = useState('Download')
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [designation, setDesignation] = useState('')
  const [companySize, setCompanySize] = useState()
  const [fileName, setFileName] = useState()
  const { section } = useParams();

  useEffect(() => {
    setFileName(whitepaperInfo[section].fileName);
  }, [section]);

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Downloading...')
    let conFom = {
      name,
      email,
      companyName,
      designation,
      companySize,
      fileName
    }
    axios({
        method: "POST",
        url:"https://api.dev.dataequity.io/website/download",
        data: conFom,
      }).then((response)=> {
        const link = document.createElement("a");
        link.href = response.data;
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setFormStatus('Download')
      })
  }


  const onNameChange = (event) => {
    const newName = event.target.value
    setName(newName)

    if (!newName.match(/^[a-z A-Z]+$/)) {
        setErrors({"name": "Please do not enter any number"});
    } else {
        setErrors({"name": ""});
    }
  }

  const onCompanyNameChange = (event) => {
    const newName = event.target.value
    setCompanyName(newName)
  }

  const onDesignationChange = (event) => {
    const newName = event.target.value
    setDesignation(newName)
  }

  const onCompanySizeChange = (event) => {
    const value = event.target.value
    setCompanySize(value)

    if (!value.match(/^[1-9]+$/)) {
        setErrors({"companySize": "Please enter a number"});
    } else {
        setErrors({"companySize": ""});
    }
  }

  const onEmailChange = (event) => {
    const email = event.target.value
    setEmail(email)

    if (!validator.isEmail(email)) {
        setErrors({"email": "Please enter a valid email"});
    } else {
        setErrors({"email": ""});
    }
  }


  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="sectionImage">
              <form onSubmit={handleSubmit} method="POST">
                <div className="form-group">
                  <span style={{ color: "red" }}>{errors["name"]}</span>
                  <input name="name" id="name" type="text" className="form-control" value={name} 
                      placeholder="Please enter your full name" onChange={(e) => onNameChange(e)} required/>
                </div>

                <div className="form-group">
                  <label className="d-flex my-1 me-2" htmlFor="email">Email address</label>
                  <input name="email" id="email" type="email" className="form-control" value={email} 
                      placeholder="Please enter your email" onChange={(e) => onEmailChange(e)} required/>
                  <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.<a className="tc" href="/terms-of-use">Terms of use</a>
                  </small>
                  <span style={{ color: "red" }}>{errors["email"]}</span>
                </div>

                <div className="form-group">
                  <label className="d-flex my-1 me-2" htmlFor="companyName"></label>
                  <input name="companyName" id="companyName" type="text" className="form-control" value={companyName} 
                      placeholder="Please enter your company name" onChange={(e) => onCompanyNameChange(e)} required/>
                </div>

                <div className="form-group">
                  <label className="d-flex my-1 me-2" htmlFor="designation">Designation</label>
                  <input name="designation" id="designation" type="text" className="form-control" value={designation} 
                      placeholder="Please enter your designation" onChange={(e) => onDesignationChange(e)} />
                </div>

                <div className="form-group">
                  <label className="d-flex my-1 me-2" htmlFor="companySize">Company Size</label>
                  <input name="companySize" id="companySize" type="number" className="form-control" value={companySize} 
                      placeholder="Please enter your company size" onChange={(e) => onCompanySizeChange(e)} />
                  <span style={{ color: "red" }}>{errors["companySize"]}</span>
                </div>
                <br/>
                <button type="submit" className="btn btn-primary">{formStatus}</button>
              </form>
          </Col>
          <Col md={4} className="sectionImage">
            <div className="whitepaper">
              <Tilt>
                <img src={pdf} className="img-fluid whitepaper-image" alt="pdf" />
              </Tilt>
              <div className="whitepaper-description">
                {whitepaperInfo[section].text}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WhitepapersUserInfo;
