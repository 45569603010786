import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <>
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
          Founded in early 2023, <span style={{fontWeight: 900}}>Data Equity</span> is a specialised Data Discovery & Valuation Platform combined with an 
          information commercialisation marketplace designed to curate and expose information 
          artefacts using a set of compliant APIs.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
          This first-of-its-kind Information <span style={{fontWeight: 900}}>Asset Valuation Framework (IAVF)</span> and information 
          monetisation platform will empower business enterprises to unlock the untapped potential 
          of their data (intangible assets) and convert it to real equity value on their balance sheet.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
    </>
  );
}

export default AboutCard;
